
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/loginPage/LoginPage";

import RegisterPage from "./pages/RegisterPage/RegisterPage";
import Wrapper from "./pages/Wrapper/Wrapper";
function App() {
  return (
    <div style={{width: "100%", height: "100%" }}>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/pages" component={Wrapper} /> */}
          {/* <Route exact path="/MachineDetailPage" component={MachineDetailPage} /> */}
          <Route exact path="/login" component={LoginPage} />
          
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/" component={LoginPage} />
          <Route path="*" component={Wrapper} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
